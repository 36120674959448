import React from 'react'
import { Typography } from 'antd'
import { Helmet } from 'react-helmet'
import MainLayout from '@layouts/MainLayout'

// const { Panel } = Collapse

export default () => (
  <MainLayout>
    <Helmet>
      <title>About this.</title>
      <description>It doesn&apos;t really matter but sure</description>
    </Helmet>
    <div className='flex flex-col'>
      <Typography.Title
        level={2}
        className='text-primary-color font-headers font-bold'
      >
        So, uh, about this.
      </Typography.Title>
      <Typography.Title
        level={3}
        className='text-primary-color font-headers font-bold'
      >
        About this site
      </Typography.Title>
      <Typography.Paragraph className='font-body text-base'>
        There&apos;s something about working in public that always motivates me.
        Maybe it&apos;s that you get to attract people interested in the same
        things as you? That you get to be held accountable for making consistent
        progress? That you get realtime feedback for products you build?
      </Typography.Paragraph>
      <Typography.Paragraph className='font-body text-base'>
        Regardless of the true nature of that motivation, I intend to keep
        myself occupied by updating this site and working on the myriad of tools
        and toys I&apos;ve been playing with in my head. I&apos;ve been meaning
        to get into writing as well, on topics that engage me. Will upload them
        here, too.
      </Typography.Paragraph>
      <Typography.Title
        level={3}
        className='text-primary-color font-headers font-bold'
      >
        About me
      </Typography.Title>
      <Typography.Paragraph className='font-body text-base'>
        Suffice it to say for now that I&apos;m someone who&apos;s lost the
        touch for crafting, lost the magic of tech. Recently I found myself
        longing for the same feeling I did back when I wrote my first program.
        This is just a reaffirmation of that same love I neglected, so here goes
        everything again.
      </Typography.Paragraph>
      <Typography.Paragraph className='font-body text-base'>
        This intro sucks, but that&apos;s all you need to know for now anyway.
        Remind me to do this again if this irks you.{' '}
        <span role='img' aria-label='laughing while crying'>
          😂
        </span>
      </Typography.Paragraph>
    </div>
  </MainLayout>
)
